@font-face {
    font-family: 'tt_slug_otfregular';
    src: url('./webfonts/ttslugotf-webfont.woff2') format('woff2'),
    url('./webfonts/ttslugotf-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'tt_slug_otfbold';
    src: url('./webfonts/ttslugotf-bold-webfont.woff2') format('woff2'),
    url('./webfonts/ttslugotf-bold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

html, body {
    height: 100%;
    width: 100%;
    background: url("./img/background.png") no-repeat center center fixed ,linear-gradient(to bottom, #254E79, #143768);
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    padding: 0;
    margin: 0;
}

#root {
    height: 100%;
    width: 100%;
    padding: 0;
    margin: 0;
}

.App {
    background-image:url('./img/yellowborder.png');
    background-size: 100% 100%;
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
    text-align: center;
    font-family: sweet-gothic, sans-serif;
    font-weight: 400;
    font-style: normal;
    letter-spacing: 1.5px;

}
.main-overlay {
    height: 100%;
    width: 100%;
    background: url("./img/overlay.png") no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}
.main-multiply {
    padding: 0;
    height: 100%;
    width: 100%;
}
.main-multiply::after{
    background: url("./img/multiply.png") no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    opacity:2%;
}

.main-logo {
    height: 100%;
    width: 100%;
    background: url("./img/gearupgetout.svg");
    background-repeat: no-repeat;
    background-position:39% 7%;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: 600px;
}

.Main-Row {
    justify-content: flex-end;
    height: 100%;
    width: 100%;
    padding: 0;
    margin: 0;
    padding-top: 300px;
    overflow: hidden;
}

@media (min-width: 0px) and (max-width: 600px) {
    .main-logo {
        height: 100%;
        width: 100%;
        background: url("./img/gearupgetoutm.svg");
        background-repeat: no-repeat;
        background-position:40% 5%;
        -webkit-background-size: cover;
        -moz-background-size: cover;
        -o-background-size: cover;
        background-size: 300px;
    }

    .Main-Row {
        justify-content: flex-end;
        height: 100%;
        width: 100%;
        padding: 0;
        margin: 0;
        padding-top: 260px;
    }
}
.Main-Container {
    text-align: center;
    height: 100%;
    width: 100%;
    padding: 0;
    margin: 0;
}

.Main-Col {
    height: 100%;
    width: 100%;
    padding: 0;
    margin: 0;
}

.sub-main-col {
    align-items: center;
    text-align: center;
    height: 100%;
    width: 100%;
    padding: 0;
    margin: 0;
}

.right-sub-main-col {
    position: absolute;
    bottom:0;
    right:0;
    width: 100%;
    padding: 0;
    margin: 0;
}

.disclaimer-container{
    background-color: white;
    width: 100%;

}
.Product-Holder {
    padding: 0;
    margin: 0;
}

.Logo-Image {
    position: relative;
    z-index: 10;
    min-width: 150px;
    max-height: 600px;
    padding: calc(15px + 1.5vmin);
    padding-top: calc(15px + 2.5vmax);
    margin-bottom: 0;
}

.Product-Image {

}
@media (min-width: 0px) and (max-width: 600px) {
    .Product-Image {
        padding: 0;
        margin: 0;
        position:relative;
        left:-40px;
        width: 130px;
    }

    .Logo-Image {
        height: 400px;
    }
}

@media (min-width: 600px){
    .Product-Image {
        padding: 0;
        margin: 0;
        position:relative;
        left:-70px;
        height: 620px;
    }
}

.Main-Left {
    min-width: 100%;
    padding: 0;
    margin: 0;
    flex: 1;
}

.vertical-bottom {
    align-items: flex-end;
}

.App-Top-Line-1 {
    text-align: center;
    font-family: tt_slug_otfregular, serif;
    font-weight: 900;
    font-style: normal;
    text-transform: capitalize;
    padding: 120px;
    padding-bottom: 0;
    padding-top: 30px;
    margin: 0;
    line-height: 120%;
    font-size: calc(14px + 1.9vmin);
    color: black;
}
.App-Top-Line-1-Alt {
    text-align: center;
    font-family: tt_slug_otfregular, serif;
    font-weight: 900;
    font-style: normal;
    text-transform: capitalize;
    padding: 80px;
    padding-bottom: 0;
    padding-top: 50px;
    margin: 0;
    line-height: 120%;
    font-size: calc(14px + 1.9vmin);
    color: black;
}

.App-Top-Line-2 {
    font-family: tt_slug_otfregular, serif;
    font-weight: 400;
    font-style: normal;
    text-transform: uppercase;
    padding: 90px;
    padding-bottom: 0;
    padding-top: 25px;
    margin: 0;
    line-height: 120%;
    font-size: calc(5px + 1.5vmin);
    color: black;
}
.App-Top-Line-3 {
    font-family: tt_slug_otfbold, serif;
    font-weight: 800;
    font-style: normal;
    text-transform: uppercase;
    padding: 0;
    padding: 15px;
    padding-top: 30px;
    margin: 0;
    line-height: 140%;
    font-size: calc(20px + 1.5vmin);
    color: #00aeef;
}

.App-Top-Line-3-Alt {
    font-family: tt_slug_otfbold, serif;
    font-weight: 800;
    font-style: normal;
    text-transform: uppercase;
    padding: 80px;
    padding-bottom: 0;
    padding-top: 100px;
    margin: 0;
    line-height: 140%;
    font-size: calc(50px + 1.5vmin);
    color: #00aeef;
}

@media (min-width: 0px) and (max-width: 600px) {
    .App-Top-Line-1 {
        text-align: left;
        font-family: tt_slug_otfregular, serif;
        font-weight: 900;
        font-style: normal;
        text-transform: capitalize;
        padding: 50px;
        padding-bottom: 0;
        padding-top: 15px;
        margin: 0;
        line-height: 120%;
        font-size: calc(10px + 1.9vmin);
        color: black;
    }

    .App-Top-Line-1-Alt {
        text-align: center;
        font-family: tt_slug_otfregular, serif;
        font-weight: 900;
        font-style: normal;
        text-transform: capitalize;
        padding: 80px;
        padding-bottom: 0;
        padding-top: 50px;
        margin: 0;
        line-height: 120%;
        font-size: calc(14px + 1.9vmin);
        color: black;
    }

    .App-Top-Line-3-Alt {
        font-family: tt_slug_otfbold, serif;
        font-weight: 800;
        font-style: normal;
        text-transform: uppercase;
        padding: 80px;
        padding-bottom: 0;
        padding-top: 20px;
        margin: 0;
        line-height: 140%;
        font-size: calc(25px + 1.5vmin);
        color: #00aeef;
    }

    .App-Top-Line-2 {
        text-align: left;
        font-family: tt_slug_otfregular, serif;
        font-weight: 400;
        font-style: normal;
        text-transform: uppercase;
        margin: 0;
        padding: 50px;
        padding-bottom: 0;
        padding-top: 20px;
        line-height: 120%;
        font-size: calc(12px);
        color: black;
    }

    .App-Top-Line-3 {
        text-align: center;
        font-family: tt_slug_otfbold, serif;
        font-weight: 900;
        font-style: normal;
        text-transform: uppercase;
        padding: 70px;
        padding-bottom: 0;
        padding-top: 10px;
        margin: 0;
        line-height: 140%;
        font-size: calc(8px + 1.5vmin);
        color: #00aeef;
    }
}
.App-Disclaimer {
    font-family: tt_slug_otfregular, serif;
    font-weight: 400;
    font-style: normal;
    text-transform: capitalize;
    text-align: justify;
    line-height: 90%;
    margin: 20px;
    margin-top: 20px;
    font-size: calc(15px + .5vmin);
    color: black;
}

.App-footer {
    font-family: tt_slug_otfregular, serif;
    font-weight: 400;
    font-style: normal;
    text-transform: uppercase;
    padding: 0;
    margin: 25px;
    margin-bottom: 0;
    margin-top: 0;
    font-size: calc(6px + .5vmin);
    color: black;
}

.Sucess-Button {
    min-width: 200px;
    margin: 25px;
    margin-bottom: 0;
    margin-top: 0;
    font-family: tt_slug_otfregular, sans-serif;
    font-weight: 700;
    font-style: normal;
    text-transform: uppercase;
    line-height: 100%;
    font-size: calc(8px + .8vmin);
    letter-spacing: 1px;
    color: black;
    background-color: #ffffff;
}

.btn-link {
    margin: 10px;
    font-family: tt_slug_otfregular, sans-serif;
    font-weight: 400;
    font-style: normal;
    text-transform: uppercase;
    line-height: 100%;
    font-size: calc(4px + .5vmin);
    color: #ffffff;
    width: 500px;
}

.linkbtn {
    margin: 10px;
    font-family: stt_slug_otfregular, sans-serif;
    font-weight: 400;
    font-style: normal;
    text-transform: uppercase;
    line-height: 100%;
    font-size: calc(4px + .5vmin);
    color: black;
    width: 500px;
}

.invalid-feedback-block {
    display: block;
}

.btn-gugo{
    padding: 0;
    margin: 0;
    width: 75px;
    height: 30px;
    font-family: tt_slug_otfregular, sans-serif;
    font-weight: 500;
    font-size: calc(4px + 1vmin);
    font-style: normal;
    letter-spacing: 4px;
    text-transform: uppercase;
    color: white;
    text-align: center;
    background-color: #00aeef;
}

.btn-gugo2{
    padding: 0;
    margin: 0;
    margin-top: 5px;
    width: 100px;
    height: 30px;
    font-family: tt_slug_otfregular, sans-serif;
    font-weight: 500;
    font-size: calc(4px + 1vmin);
    font-style: normal;
    letter-spacing: 4px;
    text-transform: uppercase;
    color: white;
    text-align: center;
    background-color: #00aeef;
}

form {
    padding: 0;
    margin: 0;
    font-family: tt_slug_otfregular, sans-serif;
    font-weight: 500;
    font-style: normal;
    text-transform: uppercase;
    font-size: calc(10px + .1vmin);
    color: white;
}

form .form-check {
    font-family: tt_slug_otfregular, sans-serif;
    font-weight: 500;
    font-style: normal;
    text-transform: uppercase;
    font-size: calc(8px + .1vmin);
    color: black;
    padding: 0;
    margin: 2px;
}

form .btn-primary {
    font-family: tt_slug_otfregular, sans-serif;
    font-weight: 500;
    font-style: normal;
    text-transform: uppercase;
    line-height: 200%;
    font-size: calc(8px + .5vmin);
    background-color: #ff9933;
}

form .select{
    padding: 0;
    margin: 0;
    width: 100px;
    font-family: tt_slug_otfregular, sans-serif;
    font-style: normal;
    color: black;
    font-size: calc(8px + .5vmin);
}

form .text{
    margin: 10px;
    width: 350px;
    height: 30px;
    font-family: tt_slug_otfregular, sans-serif;
    font-style: normal;
    text-align: center;
    font-size: calc(8px + .5vmin);
}

#name {
    padding: 0;
    margin: 0;
    font-family: tt_slug_otfregular, sans-serif;
    font-weight: 500;
    font-style: normal;
    text-transform: uppercase;
    font-size: calc(10px + .1vmin);
    color: white;

}

#age {
    padding: 0;
    margin: 0;
    font-family: tt_slug_otfregular, sans-serif;
    font-weight: 500;
    font-style: normal;
    text-transform: uppercase;
    font-size: calc(10px + .1vmin);
    color: white;
    padding-top: 5px;
}


@media (min-width: 0px) and (max-width: 600px) {

    form .select{
        padding: 0;
        margin: 0;
        width: 50px;
        font-family: tt_slug_otfregular, sans-serif;
        font-style: normal;
        color: black;
        font-size: calc(8px + .5vmin);
    }

    form .text{
        margin:0;
        width: 100%;
        height: 30px;
        font-family: tt_slug_otfregular, sans-serif;
        font-style: normal;
        text-align: center;
        font-size: calc(8px + .5vmin);
    }

    #name {
        padding: 0;
        margin: 0;
        font-family: tt_slug_otfregular, sans-serif;
        font-weight: 500;
        font-style: normal;
        text-transform: uppercase;
        font-size: calc(10px + .1vmin);
        color: white;
        text-align: center;
        padding: 55px;
        padding-top: 0;

    }
    #name .text {
        width: 110px;
        height: 25px;
        font-family: tt_slug_otfregular, sans-serif;
        font-style: normal;
        text-align: center;
        font-size: calc(8px + .5vmin);
    }

    #age {
        padding: 0;
        margin: 0;
        font-family: tt_slug_otfregular, sans-serif;
        font-weight: 500;
        font-style: normal;
        text-transform: uppercase;
        font-size: calc(10px + .1vmin);
        color: white;
        text-align: center;
    }

    form .form-check {
        font-family: tt_slug_otfregular, sans-serif;
        font-weight: 500;
        font-style: normal;
        text-transform: uppercase;
        font-size: calc(7px + .1vmin);
        color: black;
        padding: 0;
        margin: 2px;
    }
}
